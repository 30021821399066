import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d64c09b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "error-content"
};
const _hoisted_2 = {
  class: "error-title"
};
import { ssoLogoutFetch } from '@/api/login';
import { reportEvent } from '@/common';

import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const errorList = {
      9000: '网络失败',
      9001: '暂无权限',
      9501: '远程调用UserPortalServ错误',
      9502: 'ip为空，不允许登录',
      9504: '用户名为空'
    };
    const iconList = {
      9000: 9000,
      9001: 9001,
      9501: 9501,
      9502: 9502,
      9504: 9502
    };
    const errorCode = ref('');
    const againLogin = () => {
      sessionStorage.removeItem('userInfo');
      ssoLogoutFetch();
    };
    onMounted(() => {
      const {
        code
      } = route.query;
      if (code) {
        errorCode.value = code;
      } else {
        errorCode.value = '9001';
      }
      reportEvent({
        eventName: 'ui_open',
        properties: {
          ui_name: '错误页面打开'
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_a_button = _resolveComponent("a-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_svg_icon, {
        "icon-class": iconList[errorCode.value],
        "class-name": "error-icon"
      }, null, 8, ["icon-class"]), _createElementVNode("div", _hoisted_2, _toDisplayString(errorList[errorCode.value]), 1), _createVNode(_component_a_button, {
        onClick: againLogin
      }, {
        default: _withCtx(() => [_createTextVNode("退出登录")]),
        _: 1
      })]);
    };
  }
};